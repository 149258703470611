<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Relatório Descritivo
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="turma && aluno">
          <v-card-title>Relatório</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <h4>Aluno: {{ aluno.nomecompleto }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title>Professores (diários)</v-card-title>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor?.nome }} | Disciplina:
              {{ diario.disciplina?.descricao }}
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs v-model="form.semestre" background-color="primary" grow>
      <v-tab :href="`#tab-${item.value}`" v-for="item of $constants.semestres" :key="item.value">
        <!-- @change="editarBncc(item.value, matricula_id)" -->
        <v-badge color="primary">
          {{ item.desc }}
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="form.semestre">
      <v-tab-item
        v-for="item in $constants.semestres"
        :key="item.value"
        :value="'tab-' + item.value"
      >
        <v-card flat>
          <v-card-text>
            <form @submit.prevent="createOrUpdate">
              <v-textarea
                rows="10"
                label="DESCRITIVO DO ALUNO."
                v-model="formData[item.value].descricao"
              ></v-textarea>
              <v-divider class="my-5"></v-divider>
              <v-textarea
                rows="2"
                label="A CRIANÇA DEMONSTROU:"
                v-model="formData[item.value].demonstrou"
              ></v-textarea>
              <v-btn color="primary" type="submit">Enviar Dados</v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </main-template>
</template>

<script>
export default {
  async mounted() {
    await this.carrregarDescritivo(this.matricula_id);
  },
  data() {
    return {
      form: {
        semestre: 0,
      },
      formData: {
        1: {
          descricao: "",
        },
        2: {
          demonstrou: "",
        },
      },
      legendas: null,
      opcoes: null,
      sistemaBNCC: null,
      notas: null,
      aluno: null,
      matricula: null,
      turma: null,
      gestao_de_aulas: null,
      promiseCount: 0,
      tab_atual: 0,
      resumos: {},
      styleClass: [
        "red lighten-4",
        "yellow accent-1",
        "orange accent-1",
        "lime lighten-3",
        "light-green lighten-1",
      ],
      show: true,
    };
  },
  created() {
    this.loadBasicData();
  },
  methods: {
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      const { matricula_id } = this.$route.params;
      try {
        const payload = {
          1: this.formData[1] || {
            descricao: "",
          },
          2: this.formData[2] || {
            demonstrou: "",
          },
        };
        await this.$services.sistemaBNCCService.createOrUpdate(payload, matricula_id);
        this.$toast.success("Relatório Descritivo salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async carrregarDescritivo() {
      const { matricula_id } = this.$route.params;
      try {
        this.$loaderService.open("Carregando dados...");

        const response = await this.$services.sistemaBNCCService.getGestaoRelatorioDescritivo(
          matricula_id
        );

        if (response) {
          this.formData[1] =
            response.semestre_1 && response.semestre_1 !== "[]"
              ? JSON.parse(response.semestre_1)
              : {
                  descricao: "",
                };
          this.formData[2] =
            response.semestre_2 && response.semestre_2 !== "[]"
              ? JSON.parse(response.semestre_2)
              : {
                  demonstrou: "",
                };
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const payload = await this.$services.sistemaBNCCService.syncAll(
          parseInt(this.$route.params.matricula_id, 10)
        );
        this.sistemaBNCC = payload.sistema.sistema_b_n_c_c.children;
        this.legendas = payload.sistema.sistema_b_n_c_c.legenda;
        this.opcoes = Object.keys(payload.sistema.sistema_b_n_c_c.opcoes);
        this.notas = payload.notas;
        this.aluno = payload.matricula.aluno;
        this.matricula = payload.matricula;
        this.turma = payload.matricula.turma;
        this.resumos = { ...payload.resumos, matricula_id: this.matricula.id };
        this.gestao_de_aulas = payload.matricula.turma.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async salvarNotas() {
      this.promiseCount += 1;
      try {
        await this.$services.sistemaBNCCService.salvarNotas(
          parseInt(this.$route.params.matricula_id, 10),
          { notas: this.notas, resumos: this.resumos }
        );
        this.$toast.info("Relatório Salvo...");
      } catch (error) {
        this.$handleError(error);
      }
      this.promiseCount -= 1;
      this.$router.back();
    },
  },
};
</script>

<style></style>
