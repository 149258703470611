<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h6 primary white--text">
          Selecione até cinco turmas
        </v-card-title>

        <v-select
          class="px-3 my-5"
          :items="turmas"
          item-text="descricao"
          item-value="id"
          multiple
          :rules="[limitTurmas]"
          return-object
          label="Selecione uma ou mais turmas"
          v-model="turmasSelecionadas"
        ></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('dialogChange', false)"> Fechar </v-btn>
          <v-btn
            color="primary"
            text
            @click="confirmarSelecao"
            :disabled="turmasSelecionadas.length > 5"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    franquia_id: {
      type: Number,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      turmas: [],
      turmasSelecionadas: [],
    };
  },
  watch: {
    dialog: {
      async handler(newVal) {
        if (newVal) {
          await this.listarTurmasFranquiaSelect(this.franquia_id);
        }
      },
    },
  },
  methods: {
    async listarTurmasFranquiaSelect(franquia) {
      this.carregandoTurmas = true;
      try {
        const response = await this.$services.turmasService.listarTurmasFranquia({
          franquia_id: franquia,
        });
        this.turmas = response;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.carregandoTurmas = false;
      }
    },

    async confirmarSelecao() {
      if (this.turmasSelecionadas.length > 0) {
        this.$loaderService.open("Carregando dados...");

        try {
          await this.$services.relatoriosService.boletinsFranquia(
            this.franquia_id,
            this.turmasSelecionadas.map((turma) => turma.id)
          );

          this.$loaderService.close();
        } catch (error) {
          this.$toast.danger("Ocorreu um erro ao gerar o relatório.");
          this.$loaderService.close();
        }
      } else {
        this.$toast.danger("Selecione pelo menos uma turma.");
      }
    },

    limitTurmas(value) {
      if (value.length > 5) {
        return "Você pode selecionar no máximo cinco turmas.";
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
/* Estilo personalizado, se necessário */
</style>
