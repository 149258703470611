<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> DISCIPLINA:</v-card-title>
        <v-select
          :items="disciplinas"
          item-text="descricao"
          item-value="id"
          v-model="disciplina"
          return-object
          label="Selecione uma disciplina "
          solo
        ></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :dialog="dialog"
            @status="dialog = $event"
            color="blue darken-1"
            text
            @click="cancelar()"
            >Cancelar</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="gerarPlanoMensal(gestaoDeAulasId, bimestre, disciplina)"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    disciplinas: {
      type: Array,
      required: true,
    },
    gestaoDeAulasId: {
      type: Number,
    },
    bimestre: {
      type: Number,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    typeModel: {
      type: String,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
    typeModel(val) {
      this.type = val;
    },
  },
  data() {
    return {
      disciplina: null,
      disciplinasDaGestao: [],
      type: null,
    };
  },
  computed: {
    isDisabled() {
      return this.disciplinaSelecionada || !this.bimestre;
    },
  },
  methods: {
    async gerarPlanoMensal(gestaoDeAulasId, bimestre, disciplina) {
      try {
        this.$loaderService.open("Gerando plano bimestral...");
        await this.$services.relatoriosService.planoBimestral(
          parseInt(gestaoDeAulasId, 10),
          parseInt(bimestre, 10),
          parseInt(disciplina.id, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    cancelar() {
      this.disciplinaSelecionada = null;
      this.bimestre = null;
      this.$emit("status", false);
    },
  },
};
</script>
